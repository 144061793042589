import { sendMessageToIframe } from './iframeCommunication'

const observeIsNarrowScreen = (listener: (isNarrowScreen: boolean) => void) => {
  const mediaQuery = window.matchMedia('(max-width: 449px)')
  const handleIsNarrowScreen = () => listener(mediaQuery.matches)
  mediaQuery.addEventListener('change', handleIsNarrowScreen)
  handleIsNarrowScreen()
}

export const sendIsNarrowScreen = (iframe: HTMLIFrameElement) => {
  observeIsNarrowScreen(isNarrowScreen => {
    sendMessageToIframe({ type: 'IS_NARROW_SCREEN_CHANGE', value: isNarrowScreen }, iframe)
  })
}
