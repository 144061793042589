export const handlePageActivity = (onActivity: () => void) => {
  const activityEvents = [
    'mousedown',
    'mousemove',
    'keydown',
    'scroll',
    'touchstart',
    'touchmove',
  ] as const

  activityEvents.forEach(event => {
    document.addEventListener(event, onActivity, { passive: true, capture: true })
  })
  return () => {
    activityEvents.forEach(event => {
      document.removeEventListener(event, onActivity, { capture: true })
    })
  }
}
