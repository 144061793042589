export const runSlideUpAnimation = (iframe: HTMLIFrameElement) => {
  return new Promise<void>(resolve => {
    const bottom = iframe.style.bottom
    iframe.style.transform = `translateY(calc(100% + ${bottom}))`
    iframe.style.opacity = '0'

    const recover = () => {
      iframe.style.transition = 'unset'
      iframe.style.transform = 'unset'
      iframe.style.opacity = 'unset'
      resolve()
    }

    const run = () => {
      iframe.style.transition = 'transform .3s, opacity .3s'
      iframe.style.transform = 'translateY(0)'
      iframe.style.opacity = '1'
      setTimeout(recover, 300)
    }

    setTimeout(run, 300)
  })
}

export const setupResizeAnimation = (iframe: HTMLIFrameElement) => {
  iframe.style.transition =
    'width 0.4s, height 0.4s, bottom 0.3s ease-in-out 0.1s, right 0.3s ease-in-out 0.1s, left 0.3s ease-in-out 0.1s' // 0.1s delay to avoid weird movement when expanding in mobile view
}
