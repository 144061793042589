import { DeviceTypeValue } from '@chilipiper/api-client/src/chat-platform-builder'
import { sendMessageToIframe } from './iframeCommunication'

const getScrollPercentage = () => {
  const { documentElement, body: documentBody } = document

  const documentElementScrollTop = documentElement.scrollTop
  const documentBodyScrollTop = documentBody.scrollTop
  const scrollTop = documentElementScrollTop || documentBodyScrollTop

  const documentElementScrollHeight = documentElement.scrollHeight
  const documentBodyScrollHeight = documentBody.scrollHeight
  const scrollHeight = documentElementScrollHeight || documentBodyScrollHeight

  if (scrollHeight - documentElement.clientHeight === 0) {
    return 0
  }

  return (scrollTop / (scrollHeight - documentElement.clientHeight)) * 100
}

const CHECK_PAGE_UPDATE_POLLING_INTERVAL = 1000

export const storeAndSendMaxScrollPercentage = (iframe: HTMLIFrameElement) => {
  let maxScrollPercentage = 0
  let iframeLoaded = false

  const handleScroll = () => {
    const newScrollPercentage = getScrollPercentage()

    if (newScrollPercentage > maxScrollPercentage) {
      maxScrollPercentage = newScrollPercentage

      if (iframeLoaded) {
        sendMessageToIframe(
          { type: 'UPDATE_MAX_SCROLL_PERCENTAGE', value: maxScrollPercentage },
          iframe
        )
      }
    }
  }

  document.addEventListener('scroll', handleScroll, { passive: true })

  const onIframeInit = () => {
    iframeLoaded = true
    sendMessageToIframe(
      { type: 'UPDATE_MAX_SCROLL_PERCENTAGE', value: maxScrollPercentage },
      iframe
    )
    sendMessageToIframe({ type: 'UPDATE_DEVICE_TYPE', value: getDeviceType() }, iframe)
  }

  const getDeviceType = (): DeviceTypeValue => {
    if (window.matchMedia('(max-device-width: 789px)').matches) {
      return 'Mobile'
    } else if (
      window.matchMedia('(min-device-width: 789px) and (max-device-width: 1279px)').matches
    ) {
      return 'Tablet'
    } else {
      return 'Desktop'
    }
  }

  const unsubscribe = () => document.removeEventListener('scroll', handleScroll)
  const reset = () => {
    unsubscribe()
    maxScrollPercentage = 0

    sendMessageToIframe(
      { type: 'UPDATE_MAX_SCROLL_PERCENTAGE', value: maxScrollPercentage },
      iframe
    )

    document.addEventListener('scroll', handleScroll, { passive: true })
  }

  return { onIframeInit, unsubscribe, reset }
}

type UrlChangedArgs = string
export const listenOnUrlUpdates = ({
  onHrefChanged,
  onPathnameChanged,
}: Partial<{
  onHrefChanged: (args: UrlChangedArgs) => void
  onPathnameChanged: (args: UrlChangedArgs) => void
}>) => {
  let latestHref = ''
  let latestPath = ''
  setInterval(() => {
    const newHref = window.location.href
    const newPath = window.location.pathname
    if (latestPath !== newPath) {
      latestPath = newPath
      onPathnameChanged?.(newPath)
    }
    if (latestHref !== newHref) {
      latestHref = newHref
      onHrefChanged?.(newHref)
    }
  }, CHECK_PAGE_UPDATE_POLLING_INTERVAL)
}

export const storeAndSendHref = (iframe: HTMLIFrameElement) => {
  return {
    onHrefChanged: (value: UrlChangedArgs) =>
      sendMessageToIframe({ type: 'UPDATE_ORIGIN_FULL_URL', value }, iframe),
  }
}

export const storeAndSendUserTimeOnPage = (iframe: HTMLIFrameElement) => {
  let userTimeOnPage = 0
  let iframeLoaded = false

  const updateUserTimeOnPage = () => {
    userTimeOnPage++

    if (iframeLoaded) {
      sendMessageToIframe({ type: 'UPDATE_USER_TIME_ON_PAGE', value: userTimeOnPage }, iframe)
    }
  }

  let interval = setInterval(updateUserTimeOnPage, 1000)

  const onIframeInit = () => {
    iframeLoaded = true
    sendMessageToIframe({ type: 'UPDATE_USER_TIME_ON_PAGE', value: userTimeOnPage }, iframe)
  }

  const unsubscribe = () => clearInterval(interval)
  const reset = () => {
    unsubscribe()
    userTimeOnPage = 0
    sendMessageToIframe({ type: 'UPDATE_USER_TIME_ON_PAGE', value: userTimeOnPage }, iframe)
    interval = setInterval(updateUserTimeOnPage, 1000)
  }

  return { onIframeInit, unsubscribe, reset }
}
