import type { DistributedOmit } from 'type-fest'
import { captureException } from '@sentry/browser'
import { MessageToEmbed, MessageToIframe } from '../../shared/types'
import { runSlideUpAnimation, setupResizeAnimation } from './animations'

declare global {
  interface Window {
    ChiliChat?: {
      config: {
        chatUrl: string
        playbookId: string
        tenantId: string
        workspaceId: string
        zIndex: number
      }
    }
  }
}

window.ChiliChat = {
  config: {
    chatUrl: window.ChiliChat?.config.chatUrl
      ? window.ChiliChat.config.chatUrl
      : import.meta.env.VITE_CHILICHAT_URL ?? `${window.location.origin}/chat/widget/`,
    workspaceId: window.ChiliChat?.config.workspaceId as string,
    tenantId: window.ChiliChat?.config.tenantId as string,
    playbookId: window.ChiliChat?.config.playbookId as string,
    zIndex: window.ChiliChat?.config.zIndex as number,
  },
}

export const iframeUrl = window.ChiliChat.config.chatUrl
export const iframeOrigin = new URL(iframeUrl).origin

export const sendMessageToIframe = (
  message: DistributedOmit<MessageToIframe, 'source'>,
  iframe: HTMLIFrameElement
) => {
  const composedMessage: MessageToIframe = { ...message, source: 'chili-chat-embed' }
  iframe.contentWindow?.postMessage(composedMessage, iframeOrigin)
}

interface HandleMessageContext {
  iframe: HTMLIFrameElement
  unsubscribeScrollPercentage: () => void
  unsubscribeUserTimeOnPage: () => void
}

export const handleMessage = async (message: MessageToEmbed, context: HandleMessageContext) => {
  switch (message.type) {
    case 'WIDGET_STATE_CHANGE': {
      const { iframe } = context
      iframe.className = message.value
      break
    }
    case 'SET_SIZE': {
      const { height, width } = message.value
      const { iframe } = context
      const oldHeight = iframe.style.height

      iframe.style.height = height ?? iframe.style.height
      iframe.style.width = width ?? iframe.style.width

      // if it's not resizing, slide up
      if (oldHeight === '0px') {
        await runSlideUpAnimation(iframe)
        setupResizeAnimation(iframe)
      }

      break
    }
    case 'APPEARANCE_SETTINGS_CHANGE': {
      const { iframe } = context
      const { launcherPosition, bottomSpacing, sideSpacing } = message.value
      iframe.style.bottom = `${bottomSpacing}px`

      if (launcherPosition === 'left') {
        iframe.style.left = `${sideSpacing}px`
        iframe.style.right = 'unset'
      } else {
        iframe.style.right = `${sideSpacing}px`
        iframe.style.left = 'unset'
      }
      break
    }
    case 'STOP_LISTENING_FOR_LOCATION_DATA': {
      context.unsubscribeScrollPercentage()
      context.unsubscribeUserTimeOnPage()
      break
    }
    case 'ERROR': {
      captureException(message.value)
      break
    }
    default: {
      console.error(`Unexpected message from iframe: ${JSON.stringify(message)}`)
    }
  }
}
